@import "variables.less";

@import "../../../node_modules/bootstrap-less/bootstrap/index";

//@import "../../../node_modules/datatables/media/css/jquery.dataTables.css";
@import "../../../node_modules/datatables.net-bs/css/dataTables.bootstrap.css";
@import "../../../node_modules/datatables.net-buttons-bs/css/buttons.bootstrap.css";
@import "../../../node_modules/multiselect/css/multi-select.css";



@import "../../../node_modules/bootstrap-select/dist/css/bootstrap-select.min.css";

@import "template/eliteadmin.less";
@import "template/sidebar-nav.less";
@import "template/widgets.less";


//@import "icons/font-awesome/less/font-awesome.less";
//@import "icons/themify-icons/themify-icons";
//@import "icons/simple-line-icons/less/simple-line-icons";
//@import "icons/weather-icons/less/weather-icons";
//@import "icons/linea-icons/linea.less";
@import "../../../node_modules/font-awesome/less/font-awesome.less";
@import "template/pages.less";
@import "template/responsive.less";

@import "template/spinners.less";

@import "../../../node_modules/bootstrap-datetime-picker/css/bootstrap-datetimepicker.css";
@import "../../../node_modules/bootstrap-daterangepicker/daterangepicker.css";
@import "../../../node_modules/sweetalert/dist/sweetalert.css";
@import "../../../node_modules/fullcalendar/dist/fullcalendar.css";
@import "../../../node_modules/jquery-toast-plugin/src/jquery.toast.css";
@import "../../../node_modules/dropify/dist/css/dropify.min.css";

@import "../../vendor/X-editable/bootstrap-editable.css";

// OURS

@import "./css/accounting/automatedinvoice.less";
@import "./css/accounting/customerentry.less";
@import "./css/accounting/invoice.less";
@import "./css/accounting/invoiceautofilltrash.less";
@import "./css/accounting/invoiceautofill.less";
@import "./css/accounting/invoicerepository.less";
@import "./css/accounting/repositorygroup.less";
@import "./css/accounting/supplierentry.less";
@import "./css/accounting/tax.less";
@import "./css/accounting/validationcheque.less";
@import "./css/configuration/auxiliaryaccount.less";
@import "./css/configuration/generalaccount.less";
@import "./css/configuration/revision.less";
@import "./css/configuration/log.less";
@import "./css/configuration/debug.less";
@import "./css/configuration/permissionuser.less";
@import "./css/configuration/supplier.less";
@import "./css/configuration/overheads/dashboard.less";
@import "./css/configuration/overheads/invoice.less";
@import "./css/order/new-order.less";
@import "./css/class.less";
@import "./css/home.less";
@import "./css/order.less";
@import "./css/supplier_interface.less";
@import "./css/tables.less";