#table-taxes_wrapper{
	    .dt-buttons {
	position: absolute;
	top: 0;
	right: 3%;
	.btn {
		border-bottom-left-radius:2px;
		border-bottom-right-radius:2px;
	    background-color: #ff6849;
	    color: @white;
	    border: none;
	    margin: 0 5px 0 0;
		}
	}
}