#repositories-repository-group {
	tbody .sub-header th {
		text-align: center;
		color: @primary;
	}
}

#repository-group-table {
	th:first-of-type {
		width: 30px;
	}
	td:first-of-type {
		text-align: center;
	}
	th:last-of-type {
		width: 30px;
	}
	td:last-of-type {
		text-align: center;
	} 
}