

.r-icon-stats i {
  width: 66px;
  height: 66px;
  padding: 20px;
  text-align: center;
  color: #fff;
  font-size: 24px;
  display: inline-block;
  border-radius: 100%;
  vertical-align: top;
}

.r-icon-stats .bodystate{
  padding-left: 20px;
  display: inline-block;
  vertical-align: middle;
}

.r-icon-stats h5{

}