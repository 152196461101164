div.form-part {
	input[name=order] {
		width: 200px !important;
	}
	input[name=daterange] {
		width: 225px !important;
		position: relative;
	}
	div.input-group {
		.fa-calendar {
			position: absolute;
			right: 5px;
		    top: 8px;
		    z-index: 2;
		    font-size: 1.5em;
		}
	}
}

#table-supplier-entry_wrapper > input:focus {
	border: 1px solid @info !important;
	padding-top: 15px !important;
    padding-left: 7px !important;
}

.supplier-entry > input:focus {
	border: 1px solid @info !important;
	padding-top: 15px !important;
    padding-left: 7px !important;
}
#table-supplier-entry_wrapper {
   .dt-buttons {
    	position: absolute;
		top: 0;
		right: 3%;
		.btn {
			border-bottom-left-radius:2px;
			border-bottom-right-radius:2px;
		    background-color: #ff6849;
		    color: @white;
		    border: none;
		    margin: 0 5px 0 0;
		}
	}
}

#table-supplier-entry {
	tr:nth-of-type(1) {
		th {
			border-bottom: none;
		}
	}
	tr:nth-of-type(2) {
		th:nth-of-type(7) {
			border-top: none;
			border-right: none;
		}
		th:nth-of-type(8) {
			border-top: none;
			border-right: none;
			border-left: none;
		}
		th:nth-of-type(9) {
			border-top: none;
			border-left: none;
		}
	}
	tr:nth-of-type(2) {
		th:nth-of-type(10) {
			border-top: none;
			border-right: none;
		}
		th:nth-of-type(11) {
			border-right: none;
			border-left: none;
			border-top: none;
		}
		th:nth-of-type(12) {
			border-top: none;
			border-left: none;
		}
	}
	tbody {
		tr {
			td {
				padding: 8px 8px;
			}
		}
	}
	tfoot {
		th {
	    	border-bottom: none !important;
    		padding: 8px 8px;
    		color: #197ca9;
    		border: 1px solid @white;
		}
		th:first-of-type {
			text-align: right;
		}
		th:not(:first-of-type) {
			text-align: center;
		}
	}
}
#table-analyse_wrapper {
    .dt-buttons {
	position: absolute;
	top: 0;
	right: 3%;
	.btn {
		border-bottom-left-radius:2px;
		border-bottom-right-radius:2px;
	    background-color: #ff6849;
	    color: @white;
	    border: none;
	    margin: 0 5px 0 0;
		}
	}
	#table-analyse_filter{
		margin: 15px 0 0 0;
	}
}