.checkbox-container {
	display: none;
}

#table-invoice {
	thead > tr > th > input {
	    width: 100%;
	    text-align: center;
	}
	.add-discount{
		cursor: pointer;
		font-weight: bold;
		color:red;
	}
}

.dataTables_wrapper .dataTables_processing {
	position: absolute !important;
	top: 30% !important;
	left: 0 !important;
	right: 0 !important;
	margin: auto !important;
	width: 100% !important;
	background-color: transparent !important;
}

.my-checkbox {
    padding: 5px 10px;
    margin: 4px !important;
    background-color: #e4e4e4;
    border-radius: 4px;
    color: #444;	
}

.invoice-repository-section {
	padding: 10px;
	border: 1px solid #aaa;
	table.invoice-repository {
		th, td {
			text-align: center;
		}
	}
	input[name=term_date] {
		width: 130px;
    	text-align: center;
	}
	textarea[name=comment] {
	    width: 300px;
	    height: 100px;
	}
	.key-color {
		> div {
			text-align: center;
			> .square {
				width: 40px;
			    height: 20px;
    			margin: 10px 10px 0 10px;
			    display: inline-block;
			}
			> span {
			    display: inline-block;
			    vertical-align: top;
    			margin: 10px 10px 0 10px;
			}			
		}
	}
}