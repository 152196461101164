#home-table-cheque-transfer,#home-table-supplier-pending,#home-table-supplier-anwser {
	@media (max-width: 1200px) {
		td {
			font-size: 10.5px;
			padding: 8px 3.5px
		}
		.label {
			padding: 4px 9px 3px;
		}
	}
	tr, th, td {
		text-align: center;
	}
	th.sub-header {
	//	color: @primary;
		font-size: 14px;
		font-weight: 600;
		border-bottom: 1px solid #555;
		border-top: 1px solid #555;
	}
	tr > th{
		font-size: 11px;
		color: #444;
		border: none;
		font-weight: 400;
	}
	tr > td{
		color: #555;
		font-weight: 400;
		font-variant: normal;
		border-top: 1px solid #eee;
		border-bottom: none;
		border-left: none;
		border-right: none;
	}
	tbody + tbody{
		border: none;
	}
	font-family: 'Open Sans';
	font-style: normal;
	font-variant: normal;
	font-weight: 400;
	.hide-show-more{
		display: none;
	}
	.show-more{
		border: 1px solid #197ca9;
	}
}

#home-table-supplier-pending {
	td {
		text-align: center;
	}
}
.board .dashboard-kpis-title{
	font-family: 'Open Sans';
	text-align: center;
	text-transform: uppercase;
	display: block;
	//background-color: #95c2d7;
  color: #686868;
	border-bottom: 1px solid #dbdbdb;
	font-size: 20px;
}
.board .dashboard-kpi{
	font-family: 'Open Sans';
	//background-color: #95c2d7;
	color: #686868;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-bottom: 12px;

	.title,.value,.subtitle,.subvalue{
		display: block;
		text-align: center;
		text-transform: uppercase;
	}
	.value{
	//	font-size: 18px;
	}
	.subtitle{
		font-size: 11px;
	}
	.subvalue{
		font-size: 11px;
	}
}