.tabs-style-bar nav ul li a span{
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.tabs-style-bar nav ul li a {
	border: 1px solid #b9c6d2;
}

.tabs-style-bar nav ul li.tab-current a{
	border: 1px solid #e46a49;
}

#change-status-modal{
	#status-comment{
		padding: 7px;
		margin-top: 7px;
	}

	.wait-overlay{
		z-index: 1000;
		border: medium none;
		margin: 0px;
		padding: 0px;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		background-color:rgba(153, 199, 203, 0.66);
		cursor: wait;
		position: absolute;
	}

	.wait-msg-box{
		z-index: 1011;
		position: absolute;
		padding: 0px;
		margin: 0px;
		width: 40%;
		top: 25%;
		left: 30%;
		text-align: center;
		color: rgb(0, 0, 0);
		border: 1px solid rgb(255, 255, 255);
		background-color: rgb(255, 255, 255);
		cursor: wait;
	}

}

#show-order-modal{
	.editable-item{
		white-space: nowrap;
	}
}

.order-table{
	tbody{
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
		&>tr>td{
			padding: 6px;
			&:last-child{
				cursor: pointer;	
			}
		}


		
	}


	div.checkbox{
		margin-top: 0;
		margin-bottom: 0;
		height: 20px;
		input[type="checkbox"] + label:before {
			border:1px solid @darkgrey;
		}
		label{
			padding-left: 0;
		}
	}
}

.order-page{
	.dt-buttons{
		float:right;
		padding: 5px 3px;
	}
}


#multi-change-status-modal{
	table{
		td,th{
        	//  padding: 4px;
          	font-size: 0.9em;
          	vertical-align: middle;

		}
		.comment-container{
			.comment{
				font-size: 0.9em;
				width: 100%;
				display: block;
				max-height: 150px;
				border: none;
				background: transparent;
				padding: 7px;
			}
			padding: 0 !important;
		}
		tr{
			color: #333;
		}
      tr:hover{
      //  background-color: #ffd;
      }
	}
}
.order-page {
	.freshdesk-open{
		color: @success;
	}
	.freshdesk-pending{
		color: @primary;
	}
	.freshdesk-resolved{
		color: #888
	}
	.freshdesk-closed{
		color: #bfbebe
	}

	.dt-buttons {
		.btn {
		border-radius:2px;
	    background-color: #ff6849;
	    color: @white;
	    border: none;
	    margin: 0 5px 0 0;
		}
	}

	.tooltip-content {
		background: #fff;
		border-radius: 4px;
		border: 1px solid #ddd;
		box-shadow: 2px 1px 1px rgba(48, 54, 61, 0.4);
		padding: 0;
		margin: 0 0 10px -128px;
		width: 250px;
		.tooltip-text{
			color:@bodytext;
			padding: 10px;
			white-space: normal;
		}

		&:after{
			border-top-color:#fff;
		}
	}

	.tooltip-item::after {
		width: 250px;
	}

	.box-supplier{
		background-color:#f06e57 ;
		border-radius: 3px;
		table > tbody > tr > td{
			border-top: 1px solid #f09487;
			&:first-child{
				white-space: nowrap;
			}
		}
	}

	.order-color i{
		cursor: pointer;
	}



	@colorSpin: #ffc400; // Appears 3 times



	#hourglass {
		color: #6a1b9a;
		opacity: 1;
		i {
			animation: hourglass 2.4s ease-in infinite, hourglass-spin 2.4s ease-out infinite;
			opacity: 0;
		}
	}
	#hourglass {
		width: 1em;
		height: 1em;
		line-height: 1em;
		& > i {
			&:nth-child(1) {
				animation-delay: 0s, 0s;
				color: @colorSpin;
			}

			&:nth-child(2) {
				animation-delay: 0.6s, 0s;
				color: @colorSpin;
			}

			&:nth-child(3) {
				animation-delay: 1.2s, 0s;
				color: #ffab00;
			}

			&:nth-child(4) {
				animation-delay: 1.8s, 0s;
				animation: hourglass-end 2.4s ease-in infinite, hourglass-spin 2.4s ease-out infinite;
				color: @colorSpin;
			}

			&:nth-child(5) {
				animation: hourglass-spin 2.4s ease-out infinite;
				color: #006064;
				opacity: 1;
			}
		}
	}
	@keyframes hourglass{
		0% { opacity: 1 }
		24% { opacity: 0.9}
		26% { opacity: 0 }
	}
	@keyframes hourglass-end {
		0% { opacity: 0 }
		70% { opacity: 0 }
		75% { opacity: 1}
		100% { opacity: 1}
	}

}
.order-table .payment_methods{
	text-align: center;
	vertical-align: middle;
}
.order-table .payment_methods i{
	font-size: 26px;
	padding: 2px;
	color: #3a87ad;
}

#cheques-table{
	input{
		max-width: 110px;
		display: inline-block;
	}
	text-align: center;

	& > thead > tr > th{
		border: none;
	}
	th{
		text-align: center;
	}
	.prefix{
		min-width: 10px;
		display: inline-block;
	}
	#cheque-postcode-message{
		display: block;
		font-size: 10px;
		color:red
	}
}

