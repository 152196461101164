.transfer-info {
	input {
		width: 300px;
	}
	.transfer-title {
		width: 400px;
	}
	.transfer-table-invoice-repo {
		width: 100%;
		td {
			padding: 5px;
			margin: 2.5px; 
		}
		td:first-of-type {
			text-align: right;
		}
	}
	> div > div > div input {
		margin: auto;
	}
}

#invoice-repository-table {
	td:nth-of-type(9) {
		text-align: center;
		input {
			width: 18px;
		    height: 18px;
		}
	}
	td:nth-of-type(8) {
		input {
			width: 18px;
		    height: 18px;
		}
	}
}

.back-container {
	a {
		width: 300px;
		margin: auto;
		color: white;
	}
}