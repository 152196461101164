.flags {
	display: flex;
	justify-content: flex-end;
	a {
		display: block;
		height: 25px;
		width: 35px;
		background-size: 35px 30px;
		background-position: 0;
		margin: 5px;
	}
}


.supplier_interface {
	.top {
		text-align: center;
		position: relative;
		img {
			position: absolute;
			left: 0;
			top: 0;
		}
		@media screen and (max-width: 768px) {
			img {
				display: block;
				position: initial;
				margin: auto;
			}
		}
		span {
			text-decoration: underline;
		}
	}
	#supplier-interface-table {
		tr:first-of-type th {
			text-align: center;
		}
		@media screen and (max-width: 480px) {
			td select {
				width: 100px;
			}
		}
	}
	.comments {
		text-align: center;
		span {
			display: block;
			font-weight: 500;
		}
		textarea {
			width: 100%;
			height: 100px;
			max-width: 500px;
		}
	}
}
#supplier-trackings{
	.submit-tracking{
		padding: 0;
	}
	table thead label{
		padding-top:0;
	}
	table thead tr:nth-child(1) th{
		border-bottom:none;
		padding-bottom: 0;
	}
	table thead tr:nth-child(2) th{
		border-top:none;
	}

}
#suppliers_wrapper, .create-supplier, .supplier-edit {
	.btn-info{
		width: 50%;
		max-width: 300px;
		margin: 0 auto;
	}
}
.supplier-edit {
	#ms-multi-select{
		input.search_manufacturer{
			width: 100%;
			margin: 0 0 10px 0;
		}
	}
}


#btn-submit.disabled{
	opacity: 0.5;
	cursor: not-allowed;
	pointer-events: none;
}

.bootstrap-select > .dropdown-toggle.btn-muted,.bootstrap-select > .dropdown-toggle.btn-muted:focus,.bootstrap-select > .dropdown-toggle.btn-muted:hover{
	border: 1px solid red;
	color:#000000;
}

#supplier-interface-table > thead:first-child > tr:first-child > th{
	border: 1px solid #ddd;
}

.show-comments {
	text-align: center;
	span {
		display: block;
		font-weight: 400;
	}
	textarea {
		width: 100%;
		resize: none;
		background-color: #eee;
	}
}
.add-comment {
	text-align: center;
	span {
		display: block;
		font-weight: 400;
	}
	textarea {
		width: 100%;
	}
}

.add-tracking {
	text-align: center;
	margin-top: 12px;
	margin-bottom: 12px;
}


