.order-new_order-index {
  #orders {
    .panel-body {
      max-height: 600px;
      overflow: scroll;
      ul {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        li {
          display: flex;
          height: 70px;
          padding: 7px;
          border: 1px solid #ccc;
          border-bottom: 0;
          cursor: pointer;
          overflow: hidden;
          div {
            height: 100%;
          }
          div:nth-of-type(1) {
            display: flex;
            justify-content: center;
            padding-top: 5px;
            width: 30px;
            .dot {
              height: 8px;
              width: 8px;
              background-color: @info;
              border-radius: 50%;
              display: inline-block;
            }
          }
          div:nth-of-type(2) {
            width: 100%;
          }
        }
        li.selected {
          background-color: rgba(25, 124, 169, 0.2);
        }
      }
    }
  }
}