.supplier-edit {
  .btn-return {
    max-width: 300px;
    margin-left: -8px;
  }
}

#email-max{
  max-width: 100px;
}

.email-max-container{
  margin-top: 20px;
}

.inactive-filter {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #eee;
  opacity: 0.5;
}