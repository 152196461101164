.invoice-autofill-trash {
  #search-mail-form {
    label {
      padding: 0 2px 0 15px;
    }
  }
  ul.mail-list {
    list-style: none;
    border: 1px solid #ccc;
    padding: 0;
    max-height: 800px;
    overflow: scroll;
    li {
      height: 85px;
      padding: 7px;
      border-bottom: 1px solid #ccc;
      color: black;
      cursor: pointer;
      div.flag {
        text-align: center;
        flex: 0 0 14px;
      }
      div.mail-content {
        overflow: hidden;
        width: 100%;
        .flex {
          justify-content: space-between;
          span.email-from {
            font-weight: 500;
          }
        }
      }
      span.email-subject {
        display: block;
        overflow: hidden;
        white-space: nowrap;
      }
      span.email-text {
        font-size: 11px;
        display: block;
      }
    }
    li.same-uid {
      background-color: rgba(25, 124, 169, 0.1);
    }
    li.active {
      //height: 100px;
      background-color: rgba(25, 124, 169, 0.4);
    }
    li.loading {
      text-align: center;
      padding-top: 20px;
    }
  }
}

.medium-size {
  > div:nth-of-type(1) {
    .make-md-column(4);
  }
  > div:nth-of-type(2) {
    .make-md-column(8);
  }
  .flex > form > div {
    > div:nth-of-type(1) {
      .make-md-column(6);
      padding: 0 5px;
      input {
        width: 100%;
      }
    }
    > div:nth-of-type(2) {
      .make-md-column(3);
      padding: 0 5px;
    }
    > div:nth-of-type(3) {
      .make-md-column(3);
      padding: 0 5px;
    }
  }
}

.small-size {
  > div:nth-of-type(1) {
    .make-md-column(1);
  }
  > div:nth-of-type(2) {
    .make-md-column(11);
  }
}

  .popover {
  .popover-inner {
    .popover-content {
      i {
        padding: 0 4px;
      }
    }
  }
}