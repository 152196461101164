#navbar .nav .dropdown > .dropdown-menu {
	border: 1px solid #197ca9;
}

.navbar-right {
	margin-right: 0px;
  .dropdown-toggle{
    margin-left: 10px;
    border-left: 1px solid @primary ;
  }
}

.store-btn {
	width: 75px;
	text-align: center;
}

.store-btn-active {
	color: white !important;
}

.store-btn:hover {
	border-bottom: 2px solid !important;
}

.maj-btn {
	&:hover {
		border-color: @maj !important;
	}
}
.mam-btn {
	&:hover {
		border-color: @mam !important;
	}
}
.mes-btn {
	&:hover {
		border-color: @mes !important;
	}
}
.all-btn {
	&:hover {
		border-color: #03a9f3 !important;
	}
}
.maj-btn-active {
	border-bottom: 2px solid @maj!important;
}
.mam-btn-active {
	border-bottom: 2px solid @mam!important;
}
.mes-btn-active {
	border-bottom: 2px solid @mes!important;
}
.all-btn-active {
	border-bottom: 2px solid #03a9f3!important;
}

.nav-pills {
	border-bottom: 1px solid #ccc;
}

.navbar-default {
	border-bottom: 1px solid @primary;
	font-family: open sans;
	.navbar-nav{

		&> .active > a{
			border-bottom: 2px solid @primary;
			background-color: @primary;
			color: #fff;
			&:hover{
				border-bottom:2px solid @primary;
				background-color: @primary;
				color: #fff;
			}
		}
		& > li > a:hover,& > .open > a,& > .open > a:hover,& > .open > a:focus {
			background-color: #66a6c3;
			color: #fff;
			border-bottom: 2px solid @primary;
		}
		&> li > a{
			color: @primary;
			font-weight: 500;
			border-bottom: 2px solid #FFF;
		}

		.dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover{
			background-color: #66a6c3;
			color: #fff;
		}
	}
	.navbar-brand{
		color: @primary;
		border-right: 1px solid #197ca9;
		font-weight: 500;
		border-bottom: 2px solid #efefef;
		background-color: #e9e9e9;
		height: inherit;
	}
}

.editable {
	cursor: pointer;
}

.ms-container {
	width: 100% !important;	
}

.fa-info-color {
	color: @info;
}

.fa-success-color {
	color: @success;
}

.fa-danger-color {
	color: @danger2;
}

.override-btn {
	border: 0 !important;
	background-color: transparent !important;
}

.bg-title{
	overflow: visible;
	margin-bottom: 12px;
}


span.twitter-typeahead {
	width: 100%;

	.tt-menu,
	.tt-dropdown-menu
	{
		&:extend(.dropdown-menu);
	}
	.tt-menu{
		h3{
			text-align: center;
			font-size: 14px;
			border-bottom: 1px solid #ccc;
			margin: 0;
		}
		.bottom{
			display: block;
			font-size: 8px;
			margin: 0;
		}
	}
	.tt-dataset-customerentries .tt-suggestion,.tt-dataset-supplierentries .tt-suggestion{
		padding: 9px 20px 0px 20px;
	}
	.tt-suggestion {
		&:extend(.dropdown-menu > li > a);
		&.tt-cursor,
		&:hover,
		&:focus {
			&:extend(.dropdown-menu > .active > a);
		}
		&:hover{
			cursor: pointer;
		}
	}

	.input-group & {
		display: block !important;
		height: @input-height-base;
		.tt-menu,
		.tt-dropdown-menu {
			top:32px !important;
		}
		&:not(:first-child):not(:last-child) {
			.form-control {
				border-radius: 0;
			}
		}
		&:first-child {
			.form-control {
				border-top-left-radius: @input-border-radius;
				border-bottom-left-radius: @input-border-radius;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		&:last-child {
			.form-control {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				border-top-right-radius: @input-border-radius;
				border-bottom-right-radius: @input-border-radius;
			}
		}

	}
	.input-group.input-group-sm & {
		height: @input-height-small;
		.tt-menu,
		.tt-dropdown-menu {
			top:@input-height-small !important;
		}
		.form-control {
			&:extend(.input-group-sm > .form-control);
		}
	}
	.input-group.input-group-lg & {
		height: @input-height-large;
		.tt-menu,
		.tt-dropdown-menu {
			top:@input-height-large !important;
		}
		.form-control {
			&:extend(.input-group-lg > .form-control);
		}
	}
}

.label {
	border-radius: 30px !important;
}

.label-danger {
	background-color: @danger2 !important;
}

i.info-edit {
	color: @warning;
}

i.info-sign-in {
	
	color: @primary;
}
i.info-sign-out {
	color: @dark;
}

i.info-create {
	color: #5cb85c;
}

i.info-del {
	color: @danger2;
}

.border-info {
	border: 1px solid @info;
}
.fa-1\.25x {
	font-size: 1.25em;
}
.fa-1\.5x {
	font-size: 1.5em;
}
.bg-title{
	background-color:#fff ;
	border-bottom: 1px solid @primary;

	padding: 0;
	h4{

		padding: 10px 10px 10px;
		font-family: open sans;
		font-weight: 500;
		color:@primary;
		font-size: 18px;
		margin: 0;
	}
}
.modal-80 {
	width: 80%;
}

@media (max-width: 1300px) {
	.modal-80 {
		width: 95%;
	}
}

.row-same-height {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	//	display:         flex;
	flex-wrap: wrap;
	& > [class*='col-'] {
		display: flex;
		flex-direction: column;
	}
}

input[type="submit"].form-control {
	color: white;
}
.gen-table-tw{
	max-width:1200px;
	.btn-info, .btn-danger {
		max-width: 300px;
		width:100%;
		margin: 0 auto;
	}
	form{
		max-width:90%;
	}
}

.flex {
	display: flex;
}

.flex-direction-col {
	flex-direction: column;
}

.space-between {
	justify-content: space-between;
}

.font-weight-bold {
	font-weight: bold;
}

.w-100 {
	width: 100%;
}

.pos-relative {
	position: relative;
}

.text-capitalize {
	text-transform: capitalize;
}

.justify-content-between {
	justify-content: space-between;
}

.justify-content-around {
	justify-content: space-around;
}

.justify-content-center {
	justify-content: center;
}

.flex-wrap {
	flex-wrap: wrap;
}

.align-items-center {
	align-items: center;
}

@media (min-width: 1200px) {
	.modal-xlg {
		width: 90%;
	}
}


// SUBMENU NAVBAR

.dropdown-submenu {
	position: relative;
}

.dropdown-submenu>.dropdown-menu {
	top: 0;
	left: 100%;
	margin-top: -6px;
	margin-left: -1px;
	-webkit-border-radius: 0 6px 6px 6px;
	-moz-border-radius: 0 6px 6px;
	border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
	display: block;
}

.dropdown-submenu>a:after {
	display: block;
	content: " ";
	float: right;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
	border-width: 5px 0 5px 5px;
	border-left-color: #ccc;
	margin-top: 5px;
	margin-right: -10px;
}

.dropdown-submenu:hover>a:after {
	border-left-color: #fff;
}

.dropdown-submenu.pull-left {
	float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
	left: -100%;
	margin-left: 10px;
	-webkit-border-radius: 6px 0 6px 6px;
	-moz-border-radius: 6px 0 6px 6px;
	border-radius: 6px 0 6px 6px;
}

.text-maj {
	color: @maj;
}

.text-mam {
	color: @mam;
}

.text-mes {
	color: @mes;
}

.bg-dark {
  background-color: @dark;
  color: white;
}

.bg-danger-75 {
	background-color: rgb(228, 103, 115) !important;
}

.bg-danger-50 {
	background-color: rgb(237, 154, 162) !important;
}

.bg-danger-25 {
	background-color: rgb(246, 204, 208) !important;
}

.bg-success-75 {
	background-color: rgb(93, 189, 115) !important;
}

.bg-success-50 {
	background-color: rgb(147, 211, 162) !important;
}

.bg-success-25 {
	background-color: rgb(201, 233, 208) !important;
}

.bg-warning-75 {
	background-color: rgb(255, 208, 69) !important;
}

.bg-warning-50 {
	background-color: rgb(255, 224, 131) !important;
}

.bg-warning-25 {
	background-color: rgb(255, 239, 193) !important;
}

.bg-info-75 {
	background-color: rgb(81, 185, 201) !important;
}

.bg-info-50 {
	background-color: rgb(139, 208, 219) !important;
}

.bg-info-25 {
	background-color: rgb(197, 231, 237) !important;
}