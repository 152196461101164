.sandbox-button {
	display: inline-block;
	height: 15px;
	width: 15px;
	cursor: pointer;
}
.sttabs {
	.btn-info {
		background: @bluebtn;
		border: 1px solid @bluebtn;
		width:70%;
		max-width: 300px;
		margin: 0 auto;
		border-radius: 2px;
	}
	nav {
		li.tab-current {
			a {
				color:@bluebtn;
			}
			a::after {
				background: @bluebtn;
			}
		}
	}
}

#show-invoice-sandbox {
	.modal-header {
		.modal-title {
			input {
				border: 0;
				border-bottom: 1px solid #ccc;
				text-align: center;
			}
			button {
				margin-left: 15px;
			}
		}
	}
	.modal-content {
		table {
			text-align: center;
		}
	}
}
