.general-account-box {
	max-width:1200px;
	margin: 0 auto;
	.btn-info, .btn-danger {
		max-width: 300px;
		width:100%;
		margin: 0 auto;
	}
	table#general-account-table {
		tr td.last {
			text-align:right;
		}
	}
	.dt-buttons {
		position: relative;
		float:right;
		margin: -25px 0 10px 0;
		.btn {
			border-bottom-left-radius:2px;
			border-bottom-right-radius:2px;
		    background-color: #ff6849;
		    color: @white;
		    border: none;
		    margin: 0 5px 0 0;
		}
	}	
	#general-account-table_filter{
		clear: both;
	}
}
.general-account_edit{
	.btn-info {
		margin: 0 auto 10px auto;
	}
}