// --Sidebar

.sidebar {
  background:@white;
  .sidebar-nav.navbar-collapse {
        padding-left: 0;
        padding-right: 0;

    }
   .fa-fw{
      width:20px;
      text-align:left;
      display:none;
   } 
   .label{
     font-size:9px;
     width:18px;
     height:18px;
   }
}

.sidebar .sidebar-search {
    padding: 15px;
}
#side-menu li.active > a{
  /*background:rgba(0, 0, 0, 0.02);*/
}
#side-menu li a{
  color:@white;
}
#side-menu > li > a{
  color:@inverse;
  border-bottom:2px solid @white;
}


#side-menu > li > a {
  padding:15px;
  
  &:hover, &:focus{
    background:@extralight;
    border-color:@extralight;
  }
   &.active {
            
            color:@white;
        }
}
#side-menu ul > li > a {

  &:hover{
    color:@blue;
    background:@extralight;
    
  }
  &.active{
   color:@blue;

  }
}

.sidebar .arrow {
    position:absolute;
    right:15px;
    top:13px;
}

.sidebar .fa.arrow:before {
    content: "\f105";
}

.sidebar .active > a > .fa.arrow:before {
    content: "\f107";
}


.sidebar .nav-second-level li a {
    padding-left: 37px;
}

.sidebar .nav-third-level li a {
    padding-left: 52px;
}


@media(min-width:767px) {
  .nav-second-level{
      position:absolute;
      width:220px;
      
      li{
        background:@extralight;

     } 
     
  }
  #side-menu{margin-left:25px;}
  #side-menu > li > a span.arrow{
    display:none;
  }
  
  .sidebar .nav-second-level li a {
    padding-left:15px;
}

.sidebar .nav-third-level li a {
    padding-left: 30px;
}
  #side-menu > li{
      float:left;
    .collapse.in{
    display:none;
    }  
    &:hover{
       .nav-second-level, .collapse.in{
          display:block;
         
       }
       } 
  }
  /*-----Update 1.7------*/
  .nav-third-level, #side-menu .nav-second-level li .nav-third-level.collapse.in{
        position:absolute;
        width:220px;
        background:@extralight;
        left:221px;
        top:0px;
        display:none;
        box-shadow:5px 10px 20px rgba(0, 0, 0, 0.05);
      }
  #side-menu .nav-second-level li:hover{
      >a {color:@themecolor;}
      .nav-third-level, .nav-third-level.collapse.in{
        display:block;
      }
  }
  /*--------Update 1.8 -------*/
  #side-menu .nav-second-level.two-li{
    width:450px;
    background:@extralight;
    > li{
      width:50%;
      float:left;
      &:hover .nav-third-level{
        z-index:1;
        box-shadow:2px 0px 20px rgba(0, 0, 0, 0.05);
      }
    }
  }
}
.open-close{
    display:none!important;
}