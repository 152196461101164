.form-part {
	.form-horizontal {
			margin-top:20px;
		}
	}

#customerentry-table_wrapper {
    input:focus {
    	border:1px solid @info!important;
	    outline:0!important;
	    outline-offset:0!important;
    }
    .dt-buttons {
		position: absolute;
		top: 0;
		right: 3%;
		.btn {
			border-bottom-left-radius:2px;
			background-color: #ff6849;
			color: @white;
			border: none;
			margin: 0 5px 0 0;
		}
	}
}

#customerentry-table {
	th:first-of-type {
		text-align: right;
	}
	th:not(:first-of-type) {
		text-align: center;
	}
}

#swal-custom {
	input {
		display: block !important;
	}
	input[type="checkbox"] {
		height: initial !important;
	}
}