.permission-edit, .role-edit{
	h4 {
		color:@danger2;
	}
	.btn-info, .btn-danger {
    max-width: 300px;
    width:100%;
    margin: 0 auto;
  	}
  	.btn-info{
  		margin: 0 auto 10px auto;
  	}
  	.btn-return {
  		max-width: 300px;
  	}
}
.permission-box, .permission-create, .role-box, .user-box, .user-edit {
	.btn-block.btn-primary, .btn-info {
		max-width: 300px;
	    width:100%;
	    margin: 0 auto;
	    background: @bluebtn;
	    border: 1px solid @bluebtn;
  	}
    .btn-block.btn-danger{
      max-width: 300px;
      width:100%;
      margin: 0 auto;
    }
}