.configuration\.overheads\.invoices\.index {
  #mail-section {
    flex: 2;
    position: sticky;
    top: 0;
    height: 0;
    padding: 0 10px;
    ul.mail-list {
      background-color: white;
      list-style: none;
      border: 1px solid #ccc;
      padding: 0;
      max-height: 600px;
      overflow: scroll;
      li {
        height: 85px;
        padding: 7px;
        border-bottom: 1px solid #ccc;
        color: black;
        cursor: pointer;
        overflow: hidden;
        div.flag {
          text-align: center;
          flex: 0 0 14px;
        }
        div.mail-content {
          width: 100%;
          span.email-from {
            font-weight: 500;
          }
        }
        span.email-text {
          font-size: 11px;
          display: block;
        }
      }
      li.same-uid {
        background-color: rgba(25, 124, 169, 0.1);
      }
      li.active {
        background-color: rgba(25, 124, 169, 0.4);
      }
      li.loading {
        text-align: center;
        padding-top: 20px;
      }
    }
  }

  #right-section {
    flex: 8;
    padding: 0 10px;
    #form-section {
      flex: 1 0 100%;
    }
    #pdf-section {
      display: flex;
      #iframe-section {
        flex: 7;
        padding: 0 5px 0 0;
      }
      #overhead-invoice-section {
        flex: 3;
        padding: 0 0 0 5px;
        table {
          th {
            vertical-align: middle;
          }
          td.supplier {
            display: flex;
            select {
              flex: 9;
              margin-right: 5px;
            }
            button {
              flex: 1;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}