.table {
  margin-bottom: 10px;
}
.table-bordered {
	border: none;
}
.table-striped>tbody>tr:nth-of-type(odd),.table-hover > tbody > tr:hover,
.table > thead > tr > td.active, .table > tbody > tr > td.active, .table > tfoot > tr > td.active,
.table > thead > tr > th.active, .table > tbody > tr > th.active, .table > tfoot > tr > th.active,
.table > thead > tr.active > td, .table > tbody > tr.active > td, .table > tfoot > tr.active > td,
.table > thead > tr.active > th, .table > tbody > tr.active > th, .table > tfoot > tr.active > th {
    background-color: @extralight !important;
}
.table > thead > tr > td.info, .table > tbody > tr > td.info, .table > tfoot > tr > td.info,
.table > thead > tr > th.info, .table > tbody > tr > th.info, .table > tfoot > tr > th.info,
.table > thead > tr.info > td, .table > tbody > tr.info > td, .table > tfoot > tr.info > td,
.table > thead > tr.info > th, .table > tbody > tr.info > th, .table > tfoot > tr.info > th {
    background-color: #e1f4ea;
}


.gen-table > thead > tr > th, .gen-table > tbody > tr > th, .gen-table > tfoot > tr > th,
.gen-table > thead > tr > td, .gen-table > tbody > tr > td, .gen-table > tfoot > tr > td ,
.gen-table > thead > tr > th {
    border-right: 1px solid #efefef;
	border-top: 1px solid #bcbcbc;
}

.gen-table > tbody > tr > td, .gen-table > tbody > tr > th, .gen-table > tfoot > tr > td,
.gen-table > tfoot > tr > th, .gen-table > thead > tr > td, .gen-table > thead > tr > th
{
  padding:8px 8px;
}

.gen-table > thead > tr > th {
    vertical-align: bottom;
    text-transform: capitalize;
}

tbody {
  color: #333333;
}

th {
  color: #666666;
  font-weight: 500;
}

table.focus-on {
  tbody {
    tr.focused {
      th {
        background-color: @themecolor;
        color: @white;
      }
      td {
        background-color: @themecolor;
        color: @white;
      }
    }
  }
}

.table-rep-plugin {
  .table-responsive {
    border: none !important;
  }
  tbody {
    th {
      font-size: 14px;
      font-weight: normal;
    }
  }
  }
.jsgrid .jsgrid-table{ margin-bottom:0px;}
.jsgrid-selected-row>td{
  background:@extralight; 
  border-color:@extralight; 
}
.jsgrid-header-row>th{
  background:@white;
}
.footable-odd{
    background-color:@extralight;
  }


.gen-table{
	thead {
		background: #d9edf7;
		th {
			border: @white 1px solid!important;
			border-bottom: none;
			color: #197ca9;
      vertical-align: middle!important;
		}
	}
	tbody {
		border-left: 1px solid #ededed;
		tr:nth-of-type(1) {
			td {
				border-top:none;
			}
		}
		tr:nth-of-type(2n+1) {
			background-color: #fff !important;
		}
		tr:nth-of-type(2n+0) {
			background-color: #f4f8f9 !important;
		}
      &>tr:hover {
        background-color: #dfe6ff !important;
      }
		tr {
		}
	}
	tfoot {
		tr.info {
			th {
				border-top: 1px solid @white;
				color: #197ca9;
				padding: 8px 8px;
			}
		}
	}	
}
.gen-table-tw{
  max-width:1200px;
  margin: 0 auto;
  .btn-info, .btn-danger {
    max-width: 300px;
    width:100%;
    margin: 0 auto;
  }
  form{
    max-width:90%;
  }
}
.btn-return{
  padding:8px 10px;
  width:auto;
  background: @greybtn;
  color:@white;
  border-radius: 2px;
  display: block;
  margin: 0 0 20px 8px;
  text-align: center;
}
.btn-return:before {
  content:"< ";
}
.btn-return + div {
  clear: both;
}
.btn-return:hover{
  opacity: .7;
  color:#fff;
}
.btn-close{
  width:auto;
  background: @greybtn;
  color:@white;
  border-radius: 2px;
  display: block;
  text-align: center;
  float:right;
  border:none;
}
.btn-close + div {
  clear: both;
}
.btn-close:hover{
  background: @greybtn!important;
  opacity: .7!important;
  color:#fff!important;
  border-radius: 2px!important;
}
.board {
  .table {
    td {
      border-top:1px solid #bbb;
    }
    .label {
      border-radius: 4px;
    }
  }

}
.supplier-show-table{
  td,th{
    text-align: center;
  }
  .tooltip-inner{
    max-width: inherit;
  }
  th small{
    color:#999;
  }
  & > tbody > tr:hover {
    background-color: #dfe6ff !important;
  }
}

@media (max-width: @screen-sm-max) {
  .supplier-layout {
    table.dataTable thead > tr > th.sorting {
      padding: 0;
    }
    .table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
      padding: 1px;
    }
    body {
      font-size: 12px;
    }
  }
}

