.configuration\.overheads\.index {
  .flex {
    #to-pay {
      flex: 1;
    }
    #to-pay-repositories {
      flex: 1;
    }
    #missing-recurrence {
      flex: 1;
    }
    #missing-months {
      flex: 1;
    }
    div {
      margin: 0 10px;
    }
  }
}