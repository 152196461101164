.btn-auxiliary {
	border: 0 !important;
	background-color: transparent !important;
}



.auxiliary-account-box {
	max-width:1200px;
	margin: 0 auto;
	.btn-info, .btn-danger {
		max-width: 300px;
		width:100%;
		margin: 0 auto;
	}
	table#auxiliary-account-table {
		tr td.last {
			text-align:right;
		}
	}
}