#search-cheque, #new-cheque {
	padding: 8px 12px !important;
    margin-right: 5px;
}

.calendar-tab {
	display: flex;
	justify-content: space-around;
	#calendar {
	    width: 75%;
	    margin: auto;	
	}
	#encours {
		table {
			th {
				width: 120px;
			    text-align: center;
			}
			td {
				width: 120px;
			    text-align: center;
			}
		}
	}
	.fc-month-view{
		.th.fc-widget-header{
			background: #d9edf7;
			color: #197ca9;
		}
	}
	.fc-other-month {
		background-color: #eee;
	}
}

.fc-event-container {
	padding: 0 10px !important;
	.fc-day-grid-event > .fc-content {
	   white-space: initial;
	   overflow: hidden;
	}
}

#modal-loading {
	width: 50%;
    margin-left: auto;
    margin-right: auto;
	div {
		width: 100%;
	}
}

#modal-table {
	th, td {
	    text-align: center;
	}
	td.td-date {
		padding-top: 6px;
		padding-bottom: 6px;
	}
	td.change-date {
		cursor: pointer;
	}
}

.modal-backdrop{
	z-index: 1000;
	bottom: 0;
}

#edit-cheque-table {
	th, td {
		text-align: center;
	}
	tr {
		td:nth-of-type(3).modified, td:nth-of-type(7).modified {
			padding-top: 6px;
			padding-bottom: 6px;
			input {
				max-width: 150px;
			    margin: auto;
			    text-align: center;
			}			
		}
		td:nth-of-type(5).modified, td:nth-of-type(9).modified {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}
	tr.new {
		td:nth-of-type(1), td:nth-of-type(2), td:nth-of-type(4) {
			padding-top: 6px;
			padding-bottom: 6px;
			input {
				max-width: 150px;
			    margin: auto;
			    text-align: center;
			}			
		}
		td:nth-of-type(3), td:nth-of-type(5) {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}
}

.checkbox-mass-validation {
	margin-top: 0;
    margin-bottom: 0;
    height: 20px;
    text-align: center;
    label {
    	padding-left: 0;
    }
}
