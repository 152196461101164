#revisions-table {
    td, th {
        text-align: center;
    }
    .after, .before {
        position: relative;
    }
    .after::after {
        content: "";
        height: 2px;
        width: 10px;
        position: absolute;
        right: 0;
        top: 50%;
        background-color: #444;
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .before::before {
        content: "";
        position: absolute;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 0 4px 7px;
        border-color: transparent transparent transparent #444;
        top: 50%;
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}