#invoice-autofill-bis {
  #mail-section {
    position: sticky;
    top: 0;
    height: 0;
    ul.mail-list {
      background-color: white;
      list-style: none;
      border: 1px solid #ccc;
      padding: 0;
      max-height: 600px;
      overflow: scroll;
      li {
        height: 85px;
        padding: 7px;
        border-bottom: 1px solid #ccc;
        color: black;
        cursor: pointer;
        overflow: hidden;
        div.flag {
          text-align: center;
          flex: 0 0 14px;
        }
        div.mail-content {
          width: 100%;
          .flex {
            justify-content: space-between;
            span.email-from {
              font-weight: 500;
            }
          }
        }
        span.email-subject {
          display: block;
          overflow: hidden;
          white-space: nowrap;
        }
        span.email-text {
          font-size: 11px;
          display: block;
        }
      }
      li.same-uid {
        background-color: rgba(25, 124, 169, 0.1);
      }
      li.active {
        background-color: rgba(25, 124, 169, 0.4);
      }
      li.new-invoice-autofills {
        background-color: rgba(254, 192, 6, 0.3)
      }
      li.active.new-invoice-autofills {
        background-color: rgba(178, 169, 60, 0.5);
      }
      li.loading {
        text-align: center;
        padding-top: 20px;
      }
    }
  }

  #mail-section.size-10 {
    width: 10%;
    .email-date {
      display: none;
    }
  }

  #mail-section.size-25 {
    width: 25%;
  }

  .white-box {
    display: flex;
    .left-side {
      flex: 1;
    }
    .right-side {
      flex: 9;
      #search-form-section { // FORMULAIRE
        > div {
          flex: 1;
        }
        > form {
          flex: 6;

        }
      }
      #link-invoice-section {
        .add-discount{
          cursor: pointer;
          font-weight: bold;
          color:red;
        }
        .add-regul{
          cursor: pointer;
          font-weight: bold;
        }
      }
      #iframe-section {
        > iframe {
          flex: 4;
        }
        > div {
          flex: 1;
        }
        .invoice-autofill-table {
          position: sticky;
          top: 0;
        }
      }
    }
  }

  .modal-section {
    #merge-pdf-section {
      list-style: none;
      padding: 0 20px;
      > li {
        position: relative;
        padding: 10px;
        .object_filter {
          position: absolute;
          left: 10px;
          top: 10px;
          height: 211px;
          width: 150px;
          cursor: pointer;
          z-index: 1;
          span {
            padding: 5px;
            border-radius: 50%;
            line-height: 40px;
            margin-left: 5px;
          }
        }
        .object_filter:hover {
          background-color: rgba(1, 1, 1, 0.1);
        }
      }
    }
  }
}